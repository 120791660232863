import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect, memo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import Tooltip from 'components/primitives/tooltip';
import { UseSanaTexts } from 'components/sanaText';
import { generateKey } from 'utils/helpers';
import { requestNewProductConfiguration } from 'behavior/productConfigurator';
import { useIsVisualDesigner } from 'utils/hooks';

const Configurator = ({ id, productConfiguratorInfo, uomId, quantity }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [componentId] = useState(generateKey);
  const [configurationFailed, setConfigurationFailed] = useState(false);
  const isDesignerMode = useIsVisualDesigner();
  const { productConfigurator, isConfiguratorAvailable, configurationStarted } = useSelector(state => ({
    productConfigurator: state.page.productConfigurator,
    isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
    configurationStarted: state.page.productConfigurator?.started,
  }), shallowEqual);

  useEffect(() => {
    if (productConfigurator
      && !productConfigurator.configuratorUrl
      && productConfigurator.updatedById === componentId) {
      setLoading(false);
      setConfigurationFailed(true);
    }
  }, [productConfigurator]);

  if (loading && productConfigurator && productConfigurator.configuratorUrl)
    setLoading(false);

  const configureProductCallback = () => {
    if (configurationStarted)
      return;

    setLoading(true);
    dispatch(requestNewProductConfiguration({
      configuratorId: productConfiguratorInfo.configuratorId,
      modelId: productConfiguratorInfo.modelId,
      productId: id,
      quantity,
      uomId,
    }, componentId));
  };

  const button = (
    <SanaButton
      textKey="ConfigureProduct"
      titleTextKey={isConfiguratorAvailable && !configurationFailed ? 'ConfigureProduct' : ''}
      type="button"
      disabled={isDesignerMode || !isConfiguratorAvailable || configurationFailed}
      className={`${btnStyles.btnSmall} ${loading ? btnStyles.loading : ''}`}
      onClick={configureProductCallback}
    />
  );

  return isDesignerMode || isConfiguratorAvailable && !configurationFailed
    ? (
      <>
        {button}
      </>
    )
    : (
      <UseSanaTexts options={['ButtonAltText_DisabledConfigureProduct']}>
        {([DisabledConfigureProduct]) => (
          <Tooltip body={DisabledConfigureProduct} sign={false}>
            {button}
          </Tooltip>)}
      </UseSanaTexts>
    );
};

Configurator.propTypes = {
  id: PropTypes.string.isRequired,
  productConfiguratorInfo: PropTypes.shape({
    configuratorId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired,
  }).isRequired,
  uomId: PropTypes.string,
  quantity: PropTypes.number,
};

export default memo(Configurator);
